<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2" v-if="user">Customer Details : {{ user.first_name }} {{ user.last_name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body >

          <b-tabs card>

            <b-tab title="General">

                <table class="table" v-if="user">
                  <tbody>
                      <tr v-for="(v, k, index) in user" :key="'key9_'+index" v-if="k !== 'id' && k !== 'all_groups' && k !== 'details' && k !== 'active' && k !== 'created' && k !== 'policies'">
                          <th>{{ k|capitalize }}</th>
                          <td v-if="k === 'groups'">{{ userGroups }}</td>
                        <td v-else>{{ v }}</td>
                      </tr>
                      <tr v-for="(v, k, index) in user.details" :key="'key10_'+index" v-if="k !== 'id' && k !== 'all_groups' && k !== 'ignite_id'">
                        <th>{{ k|capitalize }}</th>
                          <td v-if="k === 'groups'">{{ userGroups }}</td>
                        <td v-else>{{ v }}</td>
                      </tr>
                      <tr>
                        <th>Active</th>
                        <td>{{user.active}}</td>
                      </tr>
                      <tr>
                        <th>Created</th>
                        <td>{{user.date_joined}}</td>
                      </tr>
                  </tbody>
              </table>

            </b-tab>

            <correspondence-tab v-if="user" v-bind:type="'customer'" v-bind:object="user.id"></correspondence-tab>

            <diary-tab v-if="user" v-bind:type="'customer'"></diary-tab>

            <notes-tab v-if="user" v-bind:type="'customer'" v-bind:object="user.id"></notes-tab>

            <b-tab v-if="user" title="Claims">
              <!--<b-button class="mb-2" @click="loginAsCustomer">Make A Claim On Behalf Of Customer</b-button>-->
              <emulate :id="user.id" type="Customer"/><br/>
              <b-button class="mb-2" :to="{name: 'Search Claim', params: {customer_id: user.id}}">View Claims For This Customer</b-button>
            </b-tab>

            <b-tab v-if="user" title="Policies">
              <b-button class="mb-2" :to="{name: 'Search Policy', params: {customer_id: user.id}}">View Policies For This Customer</b-button>
            </b-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <!--<b-button v-show="hasFullAccess" :to="{ name: 'UserEdit', params: { id: user.id } }" variant="outline-dark" class="float-right">Edit</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'UserSetPassword', params: { id: user.id } }" variant="outline-dark" class="float-right mr-2">Set Password</b-button>-->
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import correspondenceTab from '../utils/tabs/correspondence'
  import diaryTab from '../utils/tabs/diary'
  import notesTab from '../utils/tabs/notes'
    import EmulateCustomer from "../../EmulateCustomerButton";

  var jwt = require('jwt-simple');

  export default {
    components: {Id,
      'correspondence-tab': correspondenceTab,
      'diary-tab': diaryTab,
      'notes-tab': notesTab,
      "emulate": EmulateCustomer
    },
    props: {
      caption: {
        type: String,
        default: 'Customer Details'
      },
    },
    data: () => {
      return {
        user: null,
        pdf: null,
        pdf_link: null,
        get_document: false
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getUser() {
        axios.get('/users/customer/' + this.$route.params.id + '/').then(
          response => {
            this.user = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.user
      },
      loginAsCustomer(){
        console.log("LOGIN AS ", this.user);
        var secret = process.env.VUE_APP_IGNITE_JWT_KEY;
        var payload = {
          emulate: {
            admin_user: localStorage.getItem('userid'),
            user_type: "customer",
          },
          customer_id: this.user.details.ignite_id,
          policies: this.user.policies,
        }
        var token = jwt.encode(payload, secret);
        console.log(token);
        var url = process.env.VUE_APP_IGNITE_LOGIN_URL + token;
        window.open(url, "_blank");
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      userGroups() {
        let groups = ''

        if (this.user) {

          this.user.all_groups.filter(item => {

            let i = 0;

            this.user.groups.forEach(group => {

              if (item.value === group) {
                groups += item.text

                i += 1;

                if (this.user.groups.length > 1) {
                    groups += ', '
                }
              }
            })


          })
        }

        return groups

      },
    },
    mounted() {
      this.getUser()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getUser()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
